import Helmet from "react-helmet"
import Footer from "../../components/footer"
import NavBar from "../../components/navbar"
import BGP1 from './assets/bg_1.svg'
import BGP2 from './assets/bg_2.svg'
import { Link } from 'react-router-dom'
import './style.scss'

const NotFound = (data) => {
    return (
        <>
            <Helmet>
                <title>{data['404'].pageTitle}</title>
                {
                    data['404'].meta.map((item, index) => {
                        if (item.property) {
                            return <meta property={item.property} content={item.content} key={index} />;
                          } else if (item.name) {
                            return <meta name={item.name} content={item.content} key={index} />;
                          } else {
                            return null;
                          }
                    } )
                }
            </Helmet>
            <NavBar {...data} />
            <section className="section-global bg-shade-1 hero nf-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <div className="section-tag mb-8">Oops...</div>
                            <h1>{data['404'].title}</h1>
                            <p className="txt-1">{data['404'].subTitle}</p>
                            <Link to={data['404'].buttonLink} className='btn btn-primary btn-lg'>{data['404'].buttonText}</Link>
                        </div>
                    </div>
                </div>
                <img src={BGP1} alt="bg_1" className='bg_1' />
                <img src={BGP2} alt="bg_2" className='bg_2' />
            </section>
            <Footer {...data} />
        </>
    )
}

export default NotFound