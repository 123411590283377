import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import CookieConsent from 'react-cookie-consent';

import NotFound from './main/pages/404';
import AboutUs from './main/pages/about';
import Privacy from './main/pages/privacy';
import Cookie from './main/pages/cookie';
import Home from './main/pages/home';
import Contact from './main/pages/contact';
import FAQs from './main/pages/FAQs';
import Playground from './main/pages/playground';
import shared from './data/shared.json';
import home from './data/home.json';
import about from './data/about.json';
import contact from './data/contact.json';
import playground from './data/playground.json';
import faq from './data/faq.json';
import cookie from './data/cookie.json';
import privacy from './data/privacy.json';
import Data404 from './data/404.json';

const COOKIE_KEY = 'bootstrapx_cookie-consent';
function Stact() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID);

    // Set initial consent mode to denied
    ReactGA.gtag('consent', 'default', {
      ad_storage: 'denied',
      analytics_storage: 'denied',
      functionality_storage: 'denied',
      personalization_storage: 'denied',
      security_storage: 'denied',
    });

    if (document.cookie.includes(`${COOKIE_KEY}=true`)) {
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }
  }, []); // Empty dependency array ensures this runs only on mount

  useEffect(() => {
    if (document.cookie.includes(`${COOKIE_KEY}=true`)) {
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }
  }, [location.pathname]); // This runs whenever the pathname changes

  const handleAccept = () => {
    // Update consent mode to granted for analytics
    ReactGA.gtag('consent', 'update', {
      analytics_storage: 'granted',
    });

    // Send page view after consent is granted
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
      title: document.title,
    });
  };

  return (
    <>
      <div className="stact-container">
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home {...home} {...shared} />} />
          <Route path="/about" element={<AboutUs {...about} {...shared} />} />
          <Route path="/contact" element={<Contact {...contact} {...shared} />} />
          <Route path="/faqs" element={<FAQs {...faq} {...shared} />} />
          <Route path="/playground" element={<Playground {...playground} {...shared} />} />
          <Route path="/privacy" element={<Privacy {...privacy} {...shared} />} />
          <Route path="/cookie" element={<Cookie {...cookie} {...shared} />} />
          <Route path="*" element={<NotFound {...Data404} {...shared} />} />
        </Routes>
        <CookieConsent
          location="bottom"
          buttonText={<span style={{ fontWeight: 'bold' }}>I consent</span>}
          cookieName="bootstrapx_cookie-consent"
          style={{ background: '#2B373B', fontSize: '16px', padding: '10px' }}
          buttonStyle={{ color: '#4e503b', fontSize: '16px', padding: '10px 20px', fontWeight: 'bold' }}
          expires={180}
          onAccept={handleAccept}
        >
          This website uses cookies to ensure you get the best experience on our website. By continuing to browse or by clicking "I consent", you agree to the storing of cookies on your device. For more information, please visit our{' '}
          <a href="/cookie" style={{ color: 'cornflowerblue', textDecoration: 'underline' }}>
            Cookie Policy
          </a>{' '}
          and{' '}
          <a href="/privacy" style={{ color: 'cornflowerblue', textDecoration: 'underline' }}>
            Privacy Policy
          </a>.
        </CookieConsent>
      </div>
    </>
  );
}

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

export default function App() {
  return (
    <Router>
      <Stact />
    </Router>
  );
}
