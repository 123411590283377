import Helmet from "react-helmet"
import { Link } from "react-router-dom"
import BrandSection from "../../components/brandSection"
import ContactSection from "../../components/contactSection"
import Footer from "../../components/footer"
import NavBar from "../../components/navbar"
import ServiceCards from "../../components/serviceCards"
import TestimonialCards from "../../components/testimonialCards"
import './style.scss'

const Home = (data) => {
    return(
        <>
            <Helmet>
                <title>{data.home.pageTitle}</title>
                {
                    data.home.meta.map((item, index) => {
                        if (item.property) {
                            return <meta property={item.property} content={item.content} key={index} />;
                          } else if (item.name) {
                            return <meta name={item.name} content={item.content} key={index} />;
                          } else {
                            return null;
                          }
                    } )
                }
            </Helmet>
            <NavBar {...data}/>
            <div className="consulting">
                <Hero {...data}/>
                <TechStack {...data}/>
                <WhatWeDo {...data}/>
                <HowWeWork {...data}/>
                <Culture {...data}/>
                <Reviews {...data}/>
                <ContactSection contact_data={data.shared.contact.cta}/>
                <Footer {...data}/>
            </div>
        </>
    )
}
const TechStack = (props) => {
    // Extracting technologies data from props
    const { technologies } = props.home;

    return (
        <>
            <BrandSection brands={technologies.itemList} src={'home'} customTitle={technologies.titleText} bordered />
        </>
    );
}

const Hero = (props) => {
    // Extracting hero data from props
    const { home } = props;

    return (
        <section className="section-global no-border">
            <div className="container">
                <div className="row gy-5">
                    <div className="col-lg-6 align-self-center">
                        <h1>{home.heroTitle}</h1>
                        <p className='txt-1 mt-16 me-lg-5'>{home.heroDescription}</p>
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <img src={require(`./${home.heroImage}`)} className="img-fluid" alt={home.heroTitle} />
                    </div>
                </div>
            </div>
        </section>
    );
}


const WhatWeDo = (props) => {
    // Extracting data from props
    const { whatWeDo } = props.shared;

    return (
        <section className="section-global">
            <div className="container">
                <div className="row mb-40">
                    <div className="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 text-center">
                        <div className="section-tag mb-8">{whatWeDo.titleText}</div>
                        <h2>{whatWeDo.description}</h2>
                    </div>
                </div>
                <div className="row">
                    {whatWeDo.itemList.slice(0,8).map((service, index) => (
                        <div className="col-lg-4 col-xl-3 col-md-6 col-sm-6 gx-5 gy-4" key={index}>
                            <ServiceCards data={service} src="home" />
                        </div>
                    ))}
                </div>
                <div className="row mt-40 pt-40">
                    <div className="col d-flex align-self-center">
                        <Link to='/about#services' className='btn btn-outline btn-arrow ms-lg-auto'>
                            See all services
                            <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z" fill="black"/>
                            </svg>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
}


const HowWeWork = (props) => {
    // Extracting data from props
    const { howWeWork } = props.home;
    const firstIndex = 0;
    const secondIndex = 1;
    return (
        <section className="section-global bg-shade-1">
            <div className="container">
                <div className="row mb-40 gy-4">
                    <div className="col-lg-6 align-self-center d-flex justify-content-center align-items-center">
                        <div style={{maxWidth: '600px'}}>
                            <img src={require(`./${howWeWork.itemList[firstIndex].image}`)} className="img-fluid" alt="About"/>                                
                        </div>
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <div className="section-tag mb-8">{howWeWork.itemList[firstIndex].goalText}</div>
                        <h2 className='mb-16'>{howWeWork.itemList[firstIndex].title}</h2>
                        <p className='txt-1'>
                            {howWeWork.itemList[firstIndex].description}
                        </p>
                        {howWeWork.itemList[firstIndex].highlights.map((e, i) => <div key={i} className="txt-2 color-1 fw-500 mb-8 d-flex align-items-center">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='me-2'>
                                    <rect width="24" height="24" rx="12" fill="#0FA958"/>
                                    <path d="M6.66675 12L10.6667 16L17.3334 8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>


                                {e.text}
                            </div>)}
                        <div className='mt-16'>
                            <Link to="/about" className='arrow-link'>
                                Learn more
                                <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className='ms-1'>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z" fill="currentColor"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row pt-5 gy-4">
                    <div className="col-lg-6 align-self-center">
                        <div className="section-tag mb-8">{howWeWork.itemList[secondIndex].goalText}</div>
                        <h2 className='mb-16'>{howWeWork.itemList[secondIndex].title}</h2>
                        <p className='txt-1'>{howWeWork.itemList[secondIndex].description}</p>
                        {howWeWork.itemList[secondIndex].highlights.map((e, i) => <div key={i} className="txt-2 color-1 fw-500 mb-8 d-flex align-items-center">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='me-2'>
                                    <rect width="24" height="24" rx="12" fill="#0FA958"/>
                                    <path d="M6.66675 12L10.6667 16L17.3334 8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>


                                {e.text}
                            </div>)}
                        <div className='mt-16'>
                            <Link to="/about" className='arrow-link'>
                                Learn more
                                <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className='ms-1'>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z" fill="currentColor"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-6 align-self-center d-flex justify-content-center align-items-center">
                        <div style={{maxWidth: '600px'}}>
                            <img src={require(`./${howWeWork.itemList[secondIndex].image}`)} className="img-fluid" alt="About"/>                                
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

const Culture = (props) => {
    // Extracting data from props
    const { culture } = props.home;

    return (
        <section className="section-global">
            <div className="container container-2">
                <div className="row mb-40">
                    <div className="col-xl-8 col-lg-8 offset-xl-2 offset-lg-2 text-center">
                        <div className="section-tag mb-8">{culture.title}</div>
                        <h2>{culture.description}</h2>
                    </div>
                </div>
                <div className="row">
                    {culture.cultureData.map((item, index) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 text-center" key={index}>
                            <img src={require(`./${item.imgUrl}`)} className="img-fluid" alt={item.title}/>
                            <h3>{item.title}</h3>
                            <p className="txt-2">{item.text}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}
const Reviews = (props) => {
    // Extracting data from props
    const { customerReviews } = props.home;

    return (
        <div className="section-global bg-shade-blue">
            <div className="container">
                <div className="row mb-40 justify-content-between gy-4">
                    <div className="col-xl-5 col-lg-5">
                        <div className="section-tag mb-8">{customerReviews.titleText}</div>
                        <h2>{customerReviews.subTitleText}</h2>
                    </div>
                    <div className="col d-flex align-self-center">
                        {/* <Link to='/#' className='btn btn-outline btn-arrow ms-lg-auto'>
                            See all reviews
                            <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z" fill="black"/>
                            </svg>
                        </Link> */}
                    </div>
                </div>
                <div className="row">
                <TestimonialCards data={customerReviews.testimonialData.slice(0,3)} src="home" />
                </div>
            </div>
        </div>
    );
}


export default Home