import Helmet from 'react-helmet'
import Footer from '../../components/footer'
import './style.scss'
import NavBar from "../../components/navbar"
import ContactSection from '../../components/contactSection'
import ServiceCards from "../../components/serviceCards"
import { Link } from "react-router-dom"
import FeatureCards from '../../components/featureCards'

const About = (data) => {
    return (
        <>
            <Helmet>
                <title>{data.about.pageTitle}</title>
                {
                    data.about.meta.map((item, index) => {
                        if (item.property) {
                            return <meta property={item.property} content={item.content} key={index} />;
                          } else if (item.name) {
                            return <meta name={item.name} content={item.content} key={index} />;
                          } else {
                            return null;
                          }
                    } )
                }
            </Helmet>
            <NavBar  {...data}/>
            <div className="about-container">
                <Hero  {...data}/>
                <AboutHeroSection {...data} />
                <InfoStats  {...data} />
                <WhatWeDo {...data} />
                <Industries {...data}/>
                <Process {...data} />
                <ContactSection contact_data={data.shared.contact.cta}/>
                <Footer  {...data}/>
            </div>
        </>
    )
}

const Hero = (props) => {
    // Extracting data from props
    const { about } = props;

    return (
        <section className='section-global bg-shade-1 hero'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="section-tag mb-8">{about.title}</div>
                        <h1 className='display-1'>{about.subTitleText}</h1>
                    </div>
                </div>
            </div>
        </section>
    );
}

const AboutHeroSection = (props) => {
    // Extracting data from props
    const { about } = props;

    return (
        <section className='section-global'>
            <div className="container">
                <div className="row gy-4">
                    <div className="col-lg-6 align-self-center d-flex justify-content-center">
                        <div className='video-thumb'>
                            <a href='/'>
                                <img src={require(`./${about.heroImage}`)} className="img-fluid" alt="Hero" />
                                <svg width="77" height="77" viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="39.5" cy="39.5" r="37.5" fill="black" />
                                    <circle cx="37.5" cy="37.5" r="37" fill="#FFFB9E" stroke="black" />
                                    <path d="M49.5 37.7021C49.5 38.2851 48.9052 38.7044 48.9052 38.7044L32.2113 49.0452C30.9945 49.8428 30 49.2519 30 47.7407V27.6615C30 26.1464 30.9945 25.5594 32.2133 26.355L48.9072 36.6998C48.9053 36.6998 49.5 37.119 49.5 37.7021Z" fill="black" />
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <div className="section-tag mb-8">{about.heroTitle}</div>
                        <h2 className='mb-16'>{about.heroSubTitle}</h2>
                        <p className='txt-1 mb-20'>{about.heroDescription}</p>
                        {about.heroHighlights.map((e, i) => <div key={i} className="txt-2 color-1 fw-500 mb-8 d-flex align-items-center">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='me-2'>
                                    <rect width="24" height="24" rx="12" fill="#0FA958"/>
                                    <path d="M6.66675 12L10.6667 16L17.3334 8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>


                                {e.text}
                            </div>)}
                    </div>
                </div>
            </div>
        </section>
    );
}

const InfoStats = (props) => {
    // Extracting data from props
    const { infoStats } = props.about;

    return (
        <section className='counter-section dark'>
            <div className="container">
                <div className="row">
                    {infoStats.itemList.map((info, index) =>
                        <div className="col-lg-3 col-md-4 col-6 gy-4 text-center" key={index}>
                            <h2 className="display-1">
                                {info.count}
                            </h2>
                            <p className='txt-1'>{info.text}</p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
}

const WhatWeDo = (props) => {
    // Extracting data from props
    const { whatWeDo } = props.shared;

    return (
        <section className="section-global">
            <div className="container">
                <div className="row mb-40" id="services">
                    <div className="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 text-center">
                        <div className="section-tag mb-8">{whatWeDo.titleText}</div>
                        <h2>{whatWeDo.description}</h2>
                    </div>
                </div>
                <div className="row">
                    {whatWeDo.itemList.map((service, index) => (
                        <div className="col-lg-4 col-xl-3 col-md-6 col-sm-6 gx-5 gy-4" key={index}>
                            <ServiceCards data={service} src="home" />
                        </div>
                    ))}
                </div>
                <div className="row mt-40 pt-40">
                    <div className="col d-flex align-self-center">
                        <Link to='/contact' className='btn btn-outline btn-arrow ms-lg-auto'>
                            Reach out now
                            <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z" fill="black"/>
                            </svg>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
}

const Industries = (props) => {
    const { targetIndustries } = props.about;

    return (
        <>
            <section className='section-global bg-shade-blue'>
                <div className="container container-2">
                    <div className="row mb-40">
                        <div className="col-lg-8">
                            <div className="section-tag mb-8">{targetIndustries.titleText}</div>
                            <h2 className='mb-16'>{targetIndustries.subTitleText}</h2>
                        </div>
                    </div>
                    <div className="row gy-4">
                        {targetIndustries.itemList.map((e, i) =>
                            <div className="col-lg-3 col-md-4 col-sm-6" key={i}>
                                <div className="team-member text-center">
                                    <img src={require(`./${e.imgUrl}`)} className="img-fluid mb-16 text-center" alt={e.title} />
                                    <div className="txt-2 color-1 fw-500 text-left">{e.title}</div>
                                    <div className="txt-3  text-left">{e.description}</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}

const Process = (props) => {
    const { processExecution } = props.about;
    return (
        <>
            <section className='section-global counter-section bg-shade-1'>
                <div className="container">
                    <div className="row mb-40">
                        <div className="col-xl-6 col-lg-8 offset-xl-3 offset-lg-2 text-center">
                            <div className="section-tag mb-8">{processExecution.titleText}</div>
                            <h2>{processExecution.subTitleText}</h2>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row gy-4 gx-0 gx-md-5">
                        <FeatureCards data={processExecution.itemList} src='about'/>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About