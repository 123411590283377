import './style.scss'
import { Link } from 'react-router-dom'
const ProjectCards = ({data,src, viewText}) => {
    return(
        <>
            {
                data.map((e,i)=>
                    <div className="col-xl-4 col-lg-6 col-md-6 gy-4" key={i}>
                        { e.link.includes('https://') ?
                         <a href={e.link} className="project-link"  rel="noreferrer" target='_blank'>
                            <div className='project-card d-flex flex-column' style={{background: `${e.color}1a`}}>
                                <div className='tag fw-500' style={{color: `${e.color}`}}>{e.category}</div>
                                <div className='text txt-2 color-1 mb-16 fw-700'>{e.text}</div>
                                <div className='project-thumb mt-auto'>
                                    <img src={require(`../../pages/${src}/${e.imgUrl}`)} className="img-fluid" alt={e.category}/>
                                    <div className="project-link-overlay">
                                        {viewText}
                                    </div>
                                </div>
                            </div>
                        </a> :
                          <Link to={e.link} className="project-link"  rel="noreferrer" target='_blank'>
                          <div className='project-card d-flex flex-column' style={{background: `${e.color}1a`}}>
                              <div className='tag fw-500' style={{color: `${e.color}`}}>{e.category}</div>
                              <div className='text txt-2 color-1 mb-16 fw-700'>{e.text}</div>
                              <div className='project-thumb mt-auto'>
                                  <img src={require(`../../pages/${src}/${e.imgUrl}`)} className="img-fluid" alt={e.category}/>
                                  <div className="project-link-overlay">
                                  {viewText}
                                  </div>
                              </div>
                          </div>
                      </Link> 
                        
                    }
                    </div>
                )
            }
        </>
    )
}

export default ProjectCards